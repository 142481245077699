<template>
  <div
    :class="`total-card ${isDeleted ? '__deleted' : ''}`"
    v-if="creator"
    @click="AdminStatisticsUserMenu(creator)"
    :u="creator._id"
    cursor="pointer"
  >
    <div class="total-card-content" :style="`--color:${creator.color ? creator.color : '#ffcfcf'};--picture:url(${creator.picture})`">
      <div class="total-card-left">
        <div class="total-card-icon" :style="`--iconSize:${card.iconSize || '200%'}`">
          <UserAvatar :user="creator" :size="card.iconSize || '64px'" />
        </div>
        <div class="total-card-info">
          <div class="total-card-info-content">
            <div class="total-card-info-label">{{ creator.name }}</div>
            <div class="total-card-info-desc">
              <div>{{ card.desc }}</div>
              <div v-if="isDeleted">{{ $locale["deleted"] }}</div>
            </div>
            <ProgressBar v-if="card.progress" height="8px" :color="creator.color || '#ffcfcf'" :percent="card.progress" />
          </div>
        </div>
      </div>
      <div class="total-card-right">
        <div>
          <span class="sign">{{ card.sign }}</span>
          <span class="mount">{{ card.num }}</span>
        </div>
        <div>
          <span class="mount_label">{{ card.numLabel }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["card", "creator"],
  components: {
    ProgressBar: () => import("../widgets/ProgressBar.vue"),
  },
  computed: {
    isDeleted: function() {
      return this.creator.deleted;
    },
  },
};
</script>
